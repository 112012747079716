var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "page-header-wrapper",
    [
      _c(
        "a-card",
        [
          _vm.$access("ENT_UR_ROLE_SEARCH")
            ? _c(
                "div",
                { staticClass: "table-page-search-wrapper" },
                [
                  _c(
                    "a-form",
                    {
                      staticClass: "table-head-ground",
                      attrs: { layout: "inline" },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "table-layer" },
                        [
                          _c("jeepay-text-up", {
                            attrs: {
                              placeholder: "角色ID",
                              msg: _vm.searchData.roleId,
                            },
                            model: {
                              value: _vm.searchData.roleId,
                              callback: function ($$v) {
                                _vm.$set(_vm.searchData, "roleId", $$v)
                              },
                              expression: "searchData.roleId",
                            },
                          }),
                          _c("jeepay-text-up", {
                            attrs: {
                              placeholder: "角色名称",
                              msg: _vm.searchData.roleName,
                            },
                            model: {
                              value: _vm.searchData.roleName,
                              callback: function ($$v) {
                                _vm.$set(_vm.searchData, "roleName", $$v)
                              },
                              expression: "searchData.roleName",
                            },
                          }),
                          _c(
                            "span",
                            { staticClass: "table-page-search-submitButtons" },
                            [
                              _c(
                                "a-button",
                                {
                                  attrs: {
                                    type: "primary",
                                    icon: "search",
                                    loading: _vm.btnLoading,
                                  },
                                  on: { click: _vm.searchFunc },
                                },
                                [_vm._v("查询")]
                              ),
                              _c(
                                "a-button",
                                {
                                  staticStyle: { "margin-left": "8px" },
                                  attrs: { icon: "reload" },
                                  on: { click: () => (this.searchData = {}) },
                                },
                                [_vm._v("重置")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                  _c(
                    "div",
                    [
                      _vm.$access("ENT_UR_ROLE_ADD")
                        ? _c(
                            "a-button",
                            {
                              staticClass: "mg-b-30",
                              attrs: { type: "primary", icon: "plus" },
                              on: { click: _vm.addFunc },
                            },
                            [_vm._v("新建")]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _c("JeepayTable", {
            ref: "infoTable",
            attrs: {
              initData: true,
              reqTableDataFunc: _vm.reqTableDataFunc,
              tableColumns: _vm.tableColumns,
              searchData: _vm.searchData,
              scrollX: 500,
              rowKey: "roleId",
            },
            on: {
              btnLoadClose: function ($event) {
                _vm.btnLoading = false
              },
            },
            scopedSlots: _vm._u([
              {
                key: "roleIdSlot",
                fn: function ({ record }) {
                  return [_c("b", [_vm._v(_vm._s(record.roleId))])]
                },
              },
              {
                key: "opSlot",
                fn: function ({ record }) {
                  return [
                    _c("JeepayTableColumns", [
                      _vm.$access("ENT_UR_ROLE_EDIT")
                        ? _c(
                            "a",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.editFunc(record.roleId)
                                },
                              },
                            },
                            [_vm._v("修改")]
                          )
                        : _vm._e(),
                      _vm.$access("ENT_UR_ROLE_DEL")
                        ? _c(
                            "a",
                            {
                              staticStyle: { color: "red" },
                              on: {
                                click: function ($event) {
                                  return _vm.delFunc(record.roleId)
                                },
                              },
                            },
                            [_vm._v("删除")]
                          )
                        : _vm._e(),
                    ]),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c("InfoAddOrEdit", {
        ref: "infoAddOrEdit",
        attrs: { callbackFunc: _vm.searchFunc },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }